import React, { useEffect } from 'react';
import styles from "./DevBlog.css";
import { Link } from 'react-router-dom';


export default function DevBlog() {

    useEffect(() => {
        // Set body styles on component mount
        document.body.style.position = 'relative';
        document.body.style.overflowY = 'scroll';
        document.body.style.overflowX = 'hidden';
        document.body.style.userSelect = 'text';

        // Revert body styles on component unmount
        return () => {
          document.body.style.position = '';
          document.body.style.overflowY = '';
          document.body.style.overflowX = '';
          document.body.style.userSelect = '';

        };
      }, []);
      
  return (
    <>
    <div className='devblog-overlay'></div>
    <div className='devblog-overlay2'></div>
    <div className='devblog-overlay3'></div>
    <div className='devblog-container'>
        <h1>Dev Blog</h1>
        <h2>Welcome To The Alpha Launch!</h2>
        <h4>V1.0.0.A</h4>
        <h4>7/31/2024</h4>
        <br/>
        <p>I'm happy to release the first alpha build of this animation app!
            Inspired by the aesthetic of Flipnote Studio, I wanted to create a simple and easy to use animation tool that can be used on both mobile and PC, for free.
            Beginner level animation tools should be made easily accessible to everyone. Massive pay walls, subscriptions, and intrusive ads every click aren't cool. I have big plans for this app, and make it more than just simple animation. I'd like to see this become an app where beginners can learn and grow their skills, and where experienced users can put out high level works without being overwhelmed.
        </p>
        <p>
            Now this app is a long ways from done, we've got a lot more to add! So stick around for more updates.
        </p>
        <h3>Release Highlights</h3>
        <ul>
            <li>Draw and erase tools with a variety of brushes</li>
            <li>Brush Size</li>
            <li>Color picker, color wheel, & classic colors</li>
            <li>Undo, redo</li>
            <li>Onion skin</li>
            <li>Unlimited frames</li>
            <li>Upload audio using .mp3, .mp4, .wav, .ogg</li>
            <li>Live playback in browser</li>
            <li>Loop playback</li>
            <li>Export to MP4</li>
            <li>Clear, insert, and delete frames</li>
            <li>Flip the canvas horizontally, & vertically</li>
            <li>Framerate Slider</li>
            <li>Support for Desktop & Mobile</li>
            <li>Pan & Zoom</li>
            <li>2 Layers</li>
        </ul>
        <h3>What to expect from future patches?</h3>
        <p>
            Here's a list of things planned to be added in the future. The order listed may not correlate to the order of their release, and this list is subject to change. 
        </p>
        <ul>
            <li>Updated bi-directional onion skin</li>
            <li>Updated UI</li>
            <li>More brushes</li>
            <li>Brush transparency</li>
            <li>Line smoothness</li>
            <li>Improved Color Wheel</li>
            <li>Sound editor</li>
            <li>Upload multiple sounds</li>
            <li>Line and shape tool</li>
            <li>Frame viewer</li>
            <li>Fill tool</li>
            <li>More layers</li>
            <li>Selection tools</li>
            <li>Save as .gif</li>
            <li>Save your animation progress</li>
            <li>Different resolutions</li>
            <li>Gradients</li>
            <li>Blur filter</li>
            <li>Type text</li>
            <li>Upload images</li>
            <li>Improved zoom</li>
            <li>Canvas rotation</li>
            <li>Custom brushes</li>
            <li>Improved pixel art support</li>
        </ul>

        <h3>How can you support us?</h3>
        <p>
            Share this app with your friends, on social media, and with your followers. Post your videos anywhere you'd like. Adding a link in the description of your videos helps us out a lot!
        </p>

        <h3>Have any suggestions / bugs to report?</h3>
        <p>
            Reach out to me on twitter! <Link to="https://x.com/froghasleaf" className='url-link'>@froghasleaf</Link>
        </p>
        <p>
            Be warned, this program IS in Alpha. There's still a few bugs in the program that need to be ironed out. Some bugs can even mess up entire frames. So animate with caution! Check the link <Link to="/known-bugs" className='url-link'>here</Link> for a list of known bugs. 
        </p>
        <hr></hr>
        <Link to="/licensing" className='url-link'>Licensing</Link>
    </div>
    </>
  )
}

import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import VideoConversion from "./VideoConversion";
import ButtonToggleCount from "./ButtonToggleCount";

import IMG_LoopPlayback from "../Images/LoopPlayback.png";
import IMG_Play from "../Images/play.png";
import IMG_Pause from "../Images/pause.png";
import IMG_BackFrame from "../Images/backframe.png";
import IMG_BackAll from "../Images/backall.png";
import IMG_ForwardFrame from "../Images/forwardframe.png";
import IMG_ForwardAll from "../Images/forwardall.png";
import IMG_Clear from "../Images/Icon_Erase.png";
import IMG_Delete from "../Images/Icon_Delete.png";
import IMG_Insert from "../Images/Icon_AddFrame.png";
import IMG_AddMusic from "../Images/Icon_AddMusic.png";
import IMG_FlipHorizontal from "../Images/Icon_FlipHorizontal.png";
import IMG_FlipVertical from "../Images/Icon_FlipVertical.png";

import IMG_Copy from "../Images/Icon_Copy.png";
import IMG_Paste from "../Images/Icon_Paste.png";

export default function CanvasManagment({drawingCanvasRef, canvasContainerRef, newRef, Onion1, Onion2, Onion3, Onion4, CANVAS_WIDTH, CANVAS_HEIGHT ,isAnimationPlaying, setIsAnimationPlaying, currentIndex, setCurrentIndex, zoomOut, canvasRef2}) {

  const canvasRef = drawingCanvasRef;
  //const canvasRef2 = canvasContainerRef;
  const testRef = newRef;
  const OnionFrame1 = Onion1;
  const OnionFrame2 = Onion2;
  const OnionFrame3 = Onion3;
  const OnionFrame4 = Onion4;

  const canvasRef3 = useRef(null);

  const [savedCanvases, setSavedCanvases] = useState([]);
  const [savedCanvasesL2, setSavedCanvasesL2] = useState([]);
  const [currentLayer, setCurrentLayer] = useState(1);
  //const [currentIndex, setCurrentIndex] = useState(0);
  const [previousIndex, setPrevious] = useState(0);
  const [lastIndex, setLastIndex] = useState([0,0,0]);
  const [copiedCanvas, setcopiedCanvas] = useState([]);

  const [onionSkinCount, setOnionSkinCount] = useState(0);
  const audioRef = useRef(null);





  useEffect(() => {
    loadCanvas();
  }, [currentIndex]); // loadCanvas will be called whenever currentIndex changes


  function addNewFrame() {
    //const newP = document.createElement('p');

    // Append the new p element to the newRef current element
    //testRef.current.appendChild(newP);


    // Create a new canvas element
  //const newCanvas = document.createElement('canvas');

  // Append the new canvas to the container
  //canvasRef2.current.appendChild(newCanvas);

  //console.log(canvasRef2.current.children[1]);


    //const canvas = canvasRef2.current.children[1];
    const canvas = OnionFrame1.current;
    if (!canvas) {
      console.error('Canvas not found');
      return;
    }
    const ctx = canvas.getContext('2d');
    // Clear the current canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "gray";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    
    if (savedCanvases[previousIndex]) {
        // Create a temporary canvas
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = canvas.width;
        tempCanvas.height = canvas.height;
        const tempCtx = tempCanvas.getContext('2d');
    
        // Draw the image data onto the temporary canvas
        tempCtx.putImageData(savedCanvases[previousIndex], 0, 0);
    
        // Set the opacity to 10%
        ctx.globalAlpha = 0.1;
    
        // Draw the temporary canvas onto the main canvas
        ctx.drawImage(tempCanvas, 0, 0);
    
        // Reset the opacity to 100%
        ctx.globalAlpha = 1;
    }
    
        // Restore the saved state of the canvas
        //console.log("Load Canvas Index:" + currentIndex);
        //console.log(savedCanvases);
        if (savedCanvases[currentIndex]) {
          ctx.putImageData(savedCanvases[currentIndex], 0, 0);
        } else {
          console.error('No saved canvas data found');
          //console.log("New Frame Created!");
        }
      };
  

  const nextCanvas = () => {
    if(isNewFrameButtonReleased)
    {
      setIsNewFrameButtonReleased(false);
      saveCanvas();
      setLastIndex(prevLastIndex => [currentIndex, prevLastIndex[0], prevLastIndex[1], ...prevLastIndex.slice(3)]);
      setLastIndex(prevLastIndex => [currentIndex, ...prevLastIndex.slice(1)]);
      setCurrentIndex((prevIndex) => {
          setPrevious(prevIndex);
          const newIndex = prevIndex + 1;
          //console.log(currentIndex);
          if (newIndex === savedCanvases.length) {
              // Initialize a new canvas data entry
              setSavedCanvases(prevCanvases => [...prevCanvases, null]);
          }
          return newIndex;
      });
      loadCanvas();
    }
};
  
  const previousCanvas = () => {
    saveCanvas();
    setLastIndex(prevLastIndex => [currentIndex, prevLastIndex[0], prevLastIndex[1], ...prevLastIndex.slice(3)]);
    setLastIndex(prevLastIndex => [currentIndex, ...prevLastIndex.slice(1)]);
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    
    //console.log(currentIndex);
    loadCanvas();
  };

  const saveCanvas = () => {
    const canvas1 = canvasRef.current;
    const canvas2 = canvasRef2.current;
    if (!canvas1 || !canvas2) {
      console.error('One or both canvases not found');
      return;
    }
    const ctx1 = canvas1.getContext('2d', { willReadFrequently: true });
    const ctx2 = canvas2.getContext('2d', { willReadFrequently: true });
  
    // Save the current state of both canvases (including all brush strokes)
    let newCanvases = [...savedCanvases];
    newCanvases[currentIndex] = [
      ctx1.getImageData(0, 0, canvas1.width, canvas1.height),
      ctx2.getImageData(0, 0, canvas2.width, canvas2.height)
    ];
    setSavedCanvases(newCanvases);
    //console.log("Save Canvas Index:" + currentIndex);
  };

  const saveCanvasFinal = () => {
    const canvas1 = canvasRef.current;
    const canvas2 = canvasRef2.current;
    if (!canvas1 || !canvas2) {
      console.error('One or both canvases not found');
      return;
    }
  
    // Create new canvas elements
    const newCanvas1 = document.createElement('canvas');
    const newCanvas2 = document.createElement('canvas');
    newCanvas1.width = canvas1.width;
    newCanvas1.height = canvas1.height;
    newCanvas2.width = canvas2.width;
    newCanvas2.height = canvas2.height;
  
    // Get contexts of the new canvases
    const newCtx1 = newCanvas1.getContext('2d');
    const newCtx2 = newCanvas2.getContext('2d');
  
    // Draw the original canvases onto the new canvases
    newCtx1.drawImage(canvas1, 0, 0);
    newCtx2.drawImage(canvas2, 0, 0);
  
    // Update the savedCanvases array with the new canvas elements
    let newCanvases = [...savedCanvases];
    newCanvases[currentIndex] = [
      newCtx1.getImageData(0, 0, canvas1.width, canvas1.height),
      newCtx2.getImageData(0, 0, canvas2.width, canvas2.height)
    ];
    setSavedCanvases(newCanvases);
  
    return newCanvases;
  };

  useEffect(() => {
    //console.log("Save Canvas Index FINAL3:" + savedCanvases.length);
}, [savedCanvases]);
///////////////////////////////////////

  const loadCanvas = () => {
    
    const canvas2 = Onion1.current;
    if (!canvas2) {
      console.error('Canvas2 not found');
      return;
    }
    const ctx2 = canvas2.getContext('2d');

    const canvas3 = Onion2.current;
    if (!canvas3) {
      console.error('Canvas3 not found');
      return;
    }
    const ctx3 = canvas3.getContext('2d');


    const canvas4 = Onion3.current;
    if (!canvas4) {
      console.error('Canvas4 not found');
      return;
    }
    const ctx4 = canvas4.getContext('2d');

    const canvas = canvasRef.current;
    if (!canvas) {
      console.error('Canvas not found');
      return;
    }
    const ctx = canvas.getContext('2d');

    const canvasRef2local = canvasRef2.current;
    
    if (!canvasRef2local) {
      console.error('Canvas not found');
      return;
    }
    const ctxref2 = canvasRef2local.getContext('2d');
// Clear the current canvas
ctx.clearRect(0, 0, canvas.width, canvas.height);
//ctx.fillStyle = "white";
ctx.fillStyle = "rgba(255, 255, 255, 0)";
ctx.fillRect(0, 0, canvas.width, canvas.height);

ctxref2.clearRect(0, 0, canvas.width, canvas.height);
//ctx.fillStyle = "white";
ctxref2.fillStyle = "rgba(255, 255, 255, 0)";
ctxref2.fillRect(0, 0, canvas.width, canvas.height);

ctx2.clearRect(0, 0, canvas.width, canvas.height);
ctx2.fillStyle = "rgba(255, 255, 255, 0)";
//ctx2.fillStyle = "white";
ctx2.fillRect(0, 0, canvas.width, canvas.height);

ctx3.clearRect(0, 0, canvas.width, canvas.height);
ctx3.fillStyle = "rgba(255, 255, 255, 0)";
//ctx2.fillStyle = "white";
ctx3.fillRect(0, 0, canvas.width, canvas.height);

ctx4.clearRect(0, 0, canvas.width, canvas.height);
ctx4.fillStyle = "rgba(255, 255, 255, 0)";
//ctx2.fillStyle = "white";
ctx4.fillRect(0, 0, canvas.width, canvas.height);




    // Restore the saved state of the canvas
    //console.log("Load Canvas Index:" + currentIndex);
    //console.log(savedCanvases);
    


    if (savedCanvases[currentIndex] || savedCanvases[currentIndex + 1]) {
      try {
        // Assuming savedCanvases[currentIndex] is an array with two elements
        // First canvas data/image
        ctx.putImageData(savedCanvases[currentIndex][0], 0, 0);
        // Second canvas data/image
        ctxref2.putImageData(savedCanvases[currentIndex][1], 0, 0);
      }
      catch{
        console.error("Dev Error: Canvas not found for current index");
      }
      //The first frame of onion skin is sometimes does not render in this function
      try {
        if (onionSkinCount >= 1) {
          drawOnionSkin(ctx2, 0);
        }
        if (onionSkinCount >= 2) {
          drawOnionSkin(ctx3, 1);
        }
        if (onionSkinCount >= 3) {
          drawOnionSkin(ctx4, 2); // Corrected to use ctx4
        }
      }
      catch{
        console.error("Dev Error: Onion Skin Render Error");
      }
      
      
      
      
      //console.log("Last Indexes: " + lastIndex[0] + " " + lastIndex[1]);
    } else {
      //console.error('No saved canvas data found');
      //console.log("New Frame Created!");
      //* CREATE AN ELSE IF HERE TO FIX FIRST FRAME ONION SKIN GLITCH
      try {
        if (onionSkinCount >= 1) {
          drawOnionSkin(ctx2, 0);
        }
        if (onionSkinCount >= 2) {
          drawOnionSkin(ctx3, 1);
        }
        if (onionSkinCount >= 3) {
          
          drawOnionSkin(ctx4, 2); // Corrected to use ctx4
        }
      }
      catch(error){
        console.error("Dev Error: Onion Skin Render Part 2 Error " + error);
      }
    }
  };

  function drawOnionSkin(ctx, canvasIndex) {
    ctx.putImageData(savedCanvases[lastIndex[canvasIndex]][1], 0, 0);
  
    const offscreenCanvas = document.createElement('canvas');
    offscreenCanvas.width = CANVAS_WIDTH;
    offscreenCanvas.height = CANVAS_HEIGHT;
    const offscreenCtx = offscreenCanvas.getContext('2d');
    offscreenCtx.putImageData(savedCanvases[lastIndex[canvasIndex]][0], 0, 0);
    ctx.drawImage(offscreenCanvas, 0, 0);
  }

  const clearFrame = () => {
      let canvas = canvasRef.current;
      if (!canvas) {
        console.error('Canvas not found');
        return;
      }
      let ctx = canvas.getContext('2d');
    // Clear the current canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "transparent";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    //NEW LAYER CODE
    canvas = canvasRef2.current;
    ctx = canvas.getContext('2d');
    // Clear the current canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "transparent";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }

  const loadCanvasAnimatedFrame = (frame, canvases) => {
    currentIndex = frame;
    if (canvases[previousIndex]) {
    }
  
    // Restore the saved state of the canvas
    if (canvases[frame - 1]) {
      currentIndex = frame -1;
    } else {
      // Optionally handle the case where there is no saved canvas for the frame
      console.error('No saved canvas data found');
    }
      
  };

  const flipCanvases = (direction, canvasRefs) => {
    canvasRefs.forEach((canvasRef) => {
      const canvas = canvasRef.current;
      if (!canvas) return; // Skip if the canvas reference is not valid
      const ctx = canvas.getContext('2d');
  
      // Create a temporary canvas and context
      const tempCanvas = document.createElement('canvas');
      const tempCtx = tempCanvas.getContext('2d');
  
      // Set the dimensions of the temporary canvas
      tempCanvas.width = canvas.width;
      tempCanvas.height = canvas.height;
  
      // Draw the original canvas onto the temporary canvas
      tempCtx.drawImage(canvas, 0, 0);
  
      // Clear the original canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);
  
      // Perform the transformations on the original context
      ctx.save();
      if (direction === 'horizontal') {
        ctx.translate(canvas.width, 0);
        ctx.scale(-1, 1);
      } else if (direction === 'vertical') {
        ctx.translate(0, canvas.height);
        ctx.scale(1, -1);
      } else if (direction === '180') {
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate(Math.PI); // 180 degrees
        ctx.translate(-canvas.width / 2, -canvas.height / 2);
      }
  
      // Draw the temporary canvas onto the transformed original canvas
      ctx.drawImage(tempCanvas, 0, 0);
  
      // Restore the original context state
      ctx.restore();
    });
  };

//START COPY KEYSTROKE

useEffect(() => {
  const handleKeyDown = (event) => {
    // Preventing default copy action in a specific context can interfere with normal functionality
    // Ensure this doesn't conflict with standard application behavior
    if ((event.ctrlKey || event.metaKey) && event.key === 'c') { // Example condition to limit scope
      event.preventDefault();
      copyCanvas();
      //console.log('Copy canvas triggered');
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Control' || event.key === 'Meta' || event.key === 'z' || event.key === 'y' || event.key === 'c') {
      // No need for a state change for copyCanvas, assuming it's a one-off action per keystroke
    }
  };

  // Add event listeners
  document.addEventListener('keydown', handleKeyDown);
  document.addEventListener('keyup', handleKeyUp);

  // Cleanup function to remove event listeners
  return () => {
    document.removeEventListener('keydown', handleKeyDown);
    document.removeEventListener('keyup', handleKeyUp);
  };
},); // Assuming copyCanvas doesn't need to trigger re-attachment of listeners

//END COPY KEYSTROKE

//START PASTE KEYSTROKE

useEffect(() => {
  const handleKeyDown = (event) => {
    // Preventing default copy action in a specific context can interfere with normal functionality
    // Ensure this doesn't conflict with standard application behavior
    if ((event.ctrlKey || event.metaKey) && event.key === 'v') { // Example condition to limit scope
      event.preventDefault();
      pasteCanvas();
      //console.log('Paste canvas triggered');
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Control' || event.key === 'Meta' || event.key === 'z' || event.key === 'v' || event.key === 'c') {
      // No need for a state change for copyCanvas, assuming it's a one-off action per keystroke
    }
  };

  // Add event listeners
  document.addEventListener('keydown', handleKeyDown);
  document.addEventListener('keyup', handleKeyUp);

  // Cleanup function to remove event listeners
  return () => {
    document.removeEventListener('keydown', handleKeyDown);
    document.removeEventListener('keyup', handleKeyUp);
  };
},); // Assuming copyCanvas doesn't need to trigger re-attachment of listeners

//END PASTE KEYSTROKE



  const copyCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) {
      console.error('Canvas not found');
      return;
    }
    const canvas2 = canvasRef2.current;
    if (!canvas2) {
      console.error('Canvas2 not found');
      return;
    }
    const ctx = canvas.getContext('2d', { willReadFrequently: true });
    const ctx2 = canvas2.getContext('2d', { willReadFrequently: true });
    // Save the current state of the canvas (including all brush strokes)
    const copiedCanvases = [
      ctx.getImageData(0, 0, canvas.width, canvas.height),
      ctx2.getImageData(0, 0, canvas2.width, canvas2.height)
    ];
  
    // Assuming setCopiedCanvases is a state setter function similar to setSavedCanvases
    setcopiedCanvas(copiedCanvases);
  };

  //
  const pasteCanvas = () => {
    const canvas1 = canvasRef.current;
    const canvas2 = canvasRef2.current;
    if (!canvas1 || !canvas2) {
      console.error('One or both canvases not found');
      return;
    }
  
    // Check if copiedCanvas array has data for both canvases
    if (copiedCanvas && copiedCanvas.length === 2 && copiedCanvas.every(c => c.data && c.data.length > 0)) {
      const ctx1 = canvas1.getContext('2d');
      const ctx2 = canvas2.getContext('2d');
  
      // Clear the current canvases
      ctx1.clearRect(0, 0, canvas1.width, canvas1.height);
      ctx2.clearRect(0, 0, canvas2.width, canvas2.height);
  
      // Set background to white for both canvases
      ctx1.fillStyle = "rgba(0, 0, 0, 1)";
      ctx1.fillRect(0, 0, canvas1.width, canvas1.height);
      ctx2.fillStyle = "rgba(0, 0, 0, 1)";
      ctx2.fillRect(0, 0, canvas2.width, canvas2.height);
  
      // Paste the copied canvas data onto both canvases
      if (copiedCanvas[0]) {
        ctx1.putImageData(copiedCanvas[0], 0, 0);
      }
      if (copiedCanvas[1]) {
        ctx2.putImageData(copiedCanvas[1], 0, 0);
      }
    } else {
      console.error('No copied canvas data found or data is incomplete');
    }
  }; // End Paste

  const firstFrame = () => {
    saveCanvas();
    setCurrentIndex(0);
    loadCanvas();
  }
  const lastFrame = () => {
    saveCanvas();
    setCurrentIndex(savedCanvases.length - 1);
    loadCanvas();
  }
  const deleteFrame = () => {
    saveCanvas();
    let newCanvases = [...savedCanvases];
    newCanvases.splice(currentIndex, 1);
    setSavedCanvases(newCanvases);
    setCurrentIndex(Math.max(currentIndex - 1, 0));
    loadCanvas();
  }

  //This check fixes a bug where the first click of insert frame, wasn't adding a new frame
  const [isFirstClickInsertFrame, setIsFirstClickInsertFrame] = useState(false);
  //Bug error of duplicating current frame.
  const insertFrame = () => {
    
    let newCanvases = saveCanvasFinal();;
    /*
    if(!isFirstClickInsertFrame){
      newCanvases.splice(currentIndex, 0, null); // Insert a new frame at the current index
      setSavedCanvases(newCanvases); // Update the savedCanvases state
      setIsFirstClickInsertFrame(true);
    }
      */
    newCanvases.splice(currentIndex, 0, null); // Insert a new frame at the current index
    setSavedCanvases(newCanvases); // Update the savedCanvases state
    loadCanvas();
    clearFrame();
  };
  // useEffect hook to run after savedCanvases is updated
useEffect(() => {
  // Code here will run after savedCanvases is updated
  // For example, you might want to check the new length of savedCanvases
  //console.log('savedCanvases updated', savedCanvases.length);

  // If there's a specific function you want to run after the update, call it here
  // For instance, if you want to automatically load the new canvas or perform some checks
}, [savedCanvases]); // This tells React to rerun the effect when savedCanvases changes

  const [isLooping, setIsLooping] = useState(false);

// Inside your component
const isLoopingRef = useRef(isLooping); // Create a ref to hold the current value of isLooping

useEffect(() => {
  isLoopingRef.current = isLooping; // Update the ref whenever isLooping changes
}, [isLooping]); // Dependency array, re-run this effect when isLooping changes


  const toggleLoopPlayback = () => {
    setIsLooping(!isLooping);
  }


  //Play button currently deletes the last frame
  //Also disable drawing while playing
  const animationIntervalRef = useRef(null); // Step 1: useRef to keep track of the interval
  
  
  const playAnimation = () => {

    const newPlayStartedOnFrame = currentIndex; // Temporarily hold the new value
    //console.log(audioRef);
    let currentCanvases = saveCanvasFinal();
    let i = 1;
    firstFrame();
    zoomOut();
  
    // Step 2: Check if there's an ongoing animation
    if (animationIntervalRef.current !== null) {
      clearInterval(animationIntervalRef.current); // Clear the ongoing interval
      animationIntervalRef.current = null; // Reset the ref
      setIsAnimationPlaying(false); // Update state to reflect the animation has stopped
      Onion1.current.style.display = "block"; // Show Onion1 again
      onStop(); // Call onStop to handle any additional stopping logic
      setCurrentIndex(newPlayStartedOnFrame);
      return; // Exit the function to prevent starting a new animation
    }
  
    Onion1.current.style.display = "none";
    onPlayFromStart();
    setIsAnimationPlaying(true); // Start the animation
  
    // Step 4: Start a new animation
    animationIntervalRef.current = setInterval(() => {
      //loadCanvasAnimatedFrame(i, currentCanvases);
      if (i === currentCanvases.length) {
        //console.log(isLoopingRef.current); // Use the ref's current value to get the latest state
        if(isLoopingRef.current){
          onPlayFromStart();
          i = 0;
        } else {
          clearInterval(animationIntervalRef.current); // Clear the ongoing interval
          animationIntervalRef.current = null; // Reset the ref
          setIsAnimationPlaying(false); // Update state to reflect the animation has stopped
          Onion1.current.style.display = "block"; // Show Onion1 again
          onStop(); // Call onStop to handle any additional stopping logic
          setCurrentIndex(newPlayStartedOnFrame);
          return; // Exit the function to prevent starting a new animation
        }
      }
      setCurrentIndex(i);
      i++;
    }, 1000 / frameRate);
  }

  const [frameRate, setFrameRate] = useState(1);

  const changeFrameRate = (event) => {
    setFrameRate(event.target.value);
  };

  const handleCount = (count) => {
    setOnionSkinCount(count);
    //console.log(count); // Do something with the count here
  };

  const handleLayerSelect = (event) => {
    const layer = parseInt(event.target.value);
    setCurrentLayer(layer);
    //console.log(`Selected layer: ${layer}`);
  };

  /* Audio Setup */

  const [file, setFile] = useState(null);
  const [audioURL, setAudioURL] = useState("");

  const onFileChange = event => {
    setFile(event.target.files[0]);

    // Read the file as a data URL and set it as the audio source
    const reader = new FileReader();
    reader.onloadend = () => {
      setAudioURL(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };


  const onPlayFromStart = () => {
    //console.log("Started playing audio");
    if (audioRef.current) {
      audioRef.current.currentTime = 0; // Reset playback position
      audioRef.current.play(); // Start playback
    }
  };

  const onStop = () => {
    if (audioRef.current) {
      audioRef.current.pause(); // Stop playback
      audioRef.current.currentTime = 0; // Reset playback position
    }
  };
  
  //This function exists to prevent a bug where the new frame is created twice sometimes
  const [isNewFrameButtonReleased, setIsNewFrameButtonReleased] = useState(null);
  const onReleaseNewFrame = () => {
    setIsNewFrameButtonReleased(true);
  }
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0 });
  const sliderRef = useRef(null); // Ref for the slider
  // Initialize state with the default value of the slider
  const [sliderValue, setSliderValue] = useState(3);

  // Event handler for when the slider value changes
  const handleSliderChange = (event) => {
    setSliderValue(event.target.value);
    updateTooltipPosition(event.target.value);
  
    // Calculate the fill percentage
    const fillPercentage = ((event.target.value - event.target.min) / (event.target.max - event.target.min)) * 100;
  
    // Update the slider's background to reflect the current value
    const newBackgroundStyle = `linear-gradient(to right, #045faa ${fillPercentage}%, #a0a0a0 ${fillPercentage}%)`;
    event.target.style.background = newBackgroundStyle;
  };

  const getTooltipText = (value) => {
    switch (parseInt(value, 10)) { // Ensure value is treated as an integer
      case 1:
        {
          setFrameRate(1/5);
          return '⅕ FPS';
        }
        
      case 2:
        {
          setFrameRate(1/2);
          return '½ FPS';
        }
      case 3:
        {
          setFrameRate(1);
          return '1 FPS';
        }
      case 4:
        {
          setFrameRate(2);
          return '2 FPS';
        }
      case 5:
        {
          setFrameRate(4);
          return '4 FPS';
        }
      case 6:
        {
          setFrameRate(6);
          return '6 FPS';
        }
      case 7:
        {
          setFrameRate(8);
          return '8 FPS';
        }
      case 8:
        {
          setFrameRate(12);
          return '12 FPS';
        }
      case 9:
        {
          setFrameRate(20);
          return '20 FPS';
        }
      case 10:
        setFrameRate(24);
          return '24 FPS';
      default:
        return 'Unknown'; // Default case if none of the above matches
    }
  };

  const updateTooltipPosition = (value) => {
    const slider = sliderRef.current;
    if (slider) {
      const sliderWidth = slider.offsetWidth - 15; // Adjusted for the tooltip width
      const newLeft = ((value - slider.min) / (slider.max - slider.min)) * sliderWidth + 5*sliderWidth/100;
      const tooltipText = getTooltipText(value); // Get the text based on the value
      setTooltipPosition({ left: newLeft, text: tooltipText }); // Assuming you store the text in the same state
    }
  };

  // Adjust tooltip position on mount and when value changes
  useEffect(() => {
    updateTooltipPosition(sliderValue);
  }, [sliderValue]);

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (sliderRef.current) {
      const min = sliderRef.current.min;
      const max = sliderRef.current.max;
      const value = sliderValue; // Use sliderValue directly
      const fillPercentage = ((value - min) / (max - min)) * 100;
      const newBackgroundStyle = `linear-gradient(to right, #045faa ${fillPercentage}%, #a0a0a0 ${fillPercentage}%)`;
      sliderRef.current.style.background = newBackgroundStyle;
    }
  }, [sliderValue]); // Depend on sliderValue to update whenever it changes

  return (
    <>
    <label>Frame: {currentIndex + 1} / {(currentIndex + 1) > savedCanvases.length ? (currentIndex + 1) : savedCanvases.length}</label>
      <div className="frame-control-container">
        <button className="frame-controls" onClick={firstFrame} disabled={isAnimationPlaying || currentIndex === 0}><img src={IMG_BackAll}></img></button>
        <button className="frame-controls" onClick={previousCanvas} disabled={isAnimationPlaying || currentIndex === 0}><img src={IMG_BackFrame}></img></button>
        <button className="play-button frame-controls" onClick={playAnimation}>
           
           {isAnimationPlaying ? <img src={IMG_Pause}></img> : <img src={IMG_Play}></img>}
           </button>
        <button className="frame-controls" onClick={nextCanvas} onMouseUp={onReleaseNewFrame} disabled={isAnimationPlaying}><img src={IMG_ForwardFrame}></img></button> {/* ❚ */}
        <button className="frame-controls" onClick={lastFrame} disabled={isAnimationPlaying  || currentIndex >= savedCanvases.length -1}><img src={IMG_ForwardAll}></img></button>
      </div>
      

      <button onClick={clearFrame} disabled={isAnimationPlaying} className="icon-button">
        <div  className="icon-button-inner-container">
          <img src={IMG_Clear}></img>
          Clear Frame
        </div>
        </button>
      <button onClick={insertFrame} disabled={isAnimationPlaying} className="icon-button">
      <div  className="icon-button-inner-container">
          <img src={IMG_Insert}></img>
          Insert Frame
          </div>
        </button>
      <button onClick={deleteFrame} disabled={isAnimationPlaying} className="icon-button">
        <div  className="icon-button-inner-container">
          <img src={IMG_Delete}></img>
          Delete Frame
          </div>
        </button>
      <button onClick={() => flipCanvases('horizontal', [canvasRef, canvasRef2])} 
      disabled={isAnimationPlaying} className="icon-button">
        <div  className="icon-button-inner-container">
        <img src={IMG_FlipHorizontal} style={{height:"26px", width:"26px"}}></img>
        Flip Horizontally
        </div>
        </button>


      <button onClick={() => flipCanvases('vertical', [canvasRef, canvasRef2])} 
      disabled={isAnimationPlaying} className="icon-button">
      <div  className="icon-button-inner-container">
      <img src={IMG_FlipVertical} style={{height:"26px", width:"26px"}}></img>
        
        Flip Vertically
        </div>
        </button>
      {/* 
      <button onClick={() => flipCanvas("180")} disabled={isAnimationPlaying}>Flip 180</button>
      */}
      <br/>
      <ButtonToggleCount onCountChange={handleCount}/>
      {/* 
      <div>
      <label>
        <input type="radio" value={1} checked={currentLayer === 1} onChange={handleLayerSelect} />
        Layer 1
      </label>
      <label>
        <input type="radio" value={2} checked={currentLayer === 2} onChange={handleLayerSelect} />
        Layer 2
      </label>
      
    </div>
    */}
      <button onClick={copyCanvas} disabled={isAnimationPlaying} className="icon-button">
        <div  className="icon-button-inner-container">
          <img src={IMG_Copy}></img>
          Copy
        </div>
        </button>
        <button onClick={pasteCanvas} disabled={isAnimationPlaying} className="icon-button">
        <div  className="icon-button-inner-container">
          <img src={IMG_Paste}></img>
          Paste
        </div>
        </button>

      <br/>
      <label htmlFor="fileUpload" className="customFileUpload">
        <img src={IMG_AddMusic} style={{height:"26px", width:"26px"}} alt="Add Music Icon"/>
        Upload Audio (mp3) 
        </label>
      <input type="file" id="fileUpload" onChange={onFileChange} accept=".mp3" disabled={isAnimationPlaying} style={{ display: 'none' }}/>
      {file && <p className="selected-music-name">Selected Audio: {file.name}</p>}

      {audioURL && <audio style={{display: "none"}} ref={audioRef} controls src={audioURL}>Your browser does not support the audio element.</audio>}
      
      {/* 
      <button onClick={addNewFrame}>Add Canvas</button>
      */}

      {/* 
      
      <input style={{width: "50px"}} type="text" value={frameRate} onChange={changeFrameRate} disabled={isAnimationPlaying}/>
      <p>Custom range slider:</p>
        */}
        <label>Frame Rate: </label>

      <div className="slider-container" 
     style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}
     onMouseEnter={() => setIsHovered(true)}
     onMouseLeave={() => setIsHovered(false)}><div style={{ position: 'relative' }}>
        <input
          ref={sliderRef}
          type="range"
          min="1"
          max="10"
          value={sliderValue}
          onChange={handleSliderChange}
          onTouchStart={() => setIsHovered(true)} // Make tooltip visible on touch start
          onTouchEnd={() => setIsHovered(false)} // Hide tooltip on touch end        
          className="slider-custom"
          id="myRange"
        />
        <div
          className={`tooltip ${isHovered ? 'visible' : ''}`}
          style={{
            position: 'absolute',
            bottom: '30px',
            left: `${tooltipPosition.left}px`,
            transform: 'translateX(-50%)',
            transition: 'left 0.2s',
            opacity: isHovered ? 1 : 0, // Control visibility through opacity
            visibility: isHovered ? 'visible' : 'hidden', // Ensure it's not interactable when not visible
          }}
        >
          {tooltipPosition.text}
        </div>
        <button className="button-loop-video" onClick={toggleLoopPlayback} style={{margin:"3px", backgroundColor: isLooping ? '#95bee4' : ''}}>
        {/*{isLooping ? 'Stop Loop Playback' : 'Loop Playback'} */}
        <img src={IMG_LoopPlayback} alt="Loop Playback" style={{width: "25px", height: "25px"}}/>
      </button>
      </div>
    </div>

    
      <VideoConversion saveCanvasFinal={saveCanvasFinal} canvasManagmentRef={canvasRef} frameRate={frameRate}
      CANVAS_WIDTH={CANVAS_WIDTH} CANVAS_HEIGHT={CANVAS_HEIGHT} onAudioRefChange={audioRef} newAudioRef = {audioRef} newFile = {file}  isAnimationPlaying={isAnimationPlaying}/>
    </>
  )
}
import React, { useEffect } from 'react';
import styles from "./DevBlog.css";
import { Link } from 'react-router-dom';


export default function KnownBugs() {

    useEffect(() => {
        // Set body styles on component mount
        document.body.style.position = 'relative';
        document.body.style.overflowY = 'scroll';
        document.body.style.overflowX = 'hidden';
        document.body.style.userSelect = 'text';

        // Revert body styles on component unmount
        return () => {
          document.body.style.position = '';
          document.body.style.overflowY = '';
          document.body.style.overflowX = '';
          document.body.style.userSelect = '';

        };
      }, []);

  return (
    <>
    <div className='devblog-overlay'></div>
    <div className='devblog-overlay2'></div>
    <div className='devblog-overlay3'></div>
    <div className='devblog-container'>
        <h1>Known Bugs:</h1>
        <ul>
            <li>Canvas shifts on zoom / draw (PC Only)</li>
            <li>Drawing off screen, releasing mouse, and moving mouse back to canvas continues drawing (PC Only)</li>
            <li>Clear frame / Delete Frame button overlaps last action of undo / redo</li>
            <li>Sometimes 2nd layer can't be drawn on (Because Layer 1 is drawn with white background)</li>
            <li>Download progress box doesn't appear immediately with slow connections</li>
            <li>Onion skin doesn't update immediately on button click / sometimes doesn't render past frames</li>
            <li>Canvas Artifacts (Pixels appearing to be distorted / where they shouldn't be)</li>
        </ul>
    </div>
    </>
  )
}

import React, { useState, useEffect } from 'react'
import onion from '../Images/onion.svg'; // adjust the path as needed

export default function ButtonToggleCount({ onCountChange }) {
    const myGray = '#f3f3f3'; // replace with your own shade of gray
    const [state, setState] = useState({color: myGray, count: 0});

    useEffect(() => {
      onCountChange(state.count);
    }, [state.count]);

    const handleClick = () => {
      if (state.color === myGray) {
        setState({color: 'colored', count: 1});
      } else if (state.count < 3) {
        setState({color: 'colored', count: state.count + 1});
      } else {
        setState({color: myGray, count: 0});
      }
    }
  
    return (
      <div className='onion-skin-button'
        style={{
          backgroundColor: state.color === myGray ? myGray : '#49b322',
          width: '55px',
          height: '55px',
          display: 'inline-block',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '10px',
          cursor: 'pointer',
          position: 'relative',
          userSelect: 'none',
          border: '2px solid #3d3d3d',
          marginRight:"5px",
        }}
        onClick={handleClick}
      >
        {state.color === 'colored' && 
          <div 
            style={{
              borderRadius: '50%', 
              backgroundColor: '#0151ff', 
              color: 'white', 
              position: 'absolute', 
              top: '-7px', 
              right: '-7px', 
              width: '25px', 
              height: '25px', 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center',
              fontSize: '16px',
            }}
          >
            {state.count}
          </div>
        }
        <img src={onion} alt="Onion" style={{
              top: '8px',
              position: 'relative',
            }}/>
      </div>
    )
  }
import React, { useState, useEffect, useRef } from "react";
import CanvasManagment from './CanvasManagment';

import IMG_ColorPicker from '../Images/color-picker.svg';
import IMG_Brush from '../Images/brush.svg';
import IMG_Brush_80 from '../Images/Brush_Percent_80.png';
import IMG_Brush_50 from '../Images/Brush_50_Percent.png';
import IMG_Brush_20 from '../Images/Brush_20_Percent.png';
import IMG_Brush_10 from '../Images/Brush_10_Percent.png';
import IMG_Brush_AA from '../Images/Brush_AA.png';
import IMG_Eraser from '../Images/Eraser.png';
import IMG_Brush_Pixel from '../Images/Brush_pixel.png';
import IMG_Brush_Horizontal from '../Images/Brush_Lines_Horizontal.png';
import IMG_Brush_Vertical from '../Images/Brush_Lines_Vertical.png';
import IMG_Undo from '../Images/Undo.png';
import IMG_Redo from '../Images/Redo.png';
import IMG_ZoomIn from '../Images/zoom-in.svg';
import IMG_ZoomOut from '../Images/zoom-out.svg';
import IMG_Pan from '../Images/cursor-hand-open-line.svg';
import IMG_Pixel_Brush from '../Images/pixel_brush.png';

export default function CanvasDrawing() {

    const CANVAS_HEIGHT = 400;
    const CANVAS_WIDTH = 400;
    const [drawing, setDrawing] = useState(false);
    const [ctx, setCtx] = useState();
    const canvasRef = useRef(null);
    const canvasRef2 = useRef(null);
    const currentSelectedCanvasRef = useRef(null);
    const Onion1 = useRef(null);
    const Onion2 = useRef(null);
    const Onion3 = useRef(null);
    const Onion4 = useRef(null);
    const CanvasBackground = useRef(null);
    const canvasContainerRef = useRef(null);
    const newRef = useRef(null);
    const [isErasing, setIsErasing] = useState(false);
    const [brushSize, setbrushSize] = useState(10);
    const [Opacity, setOpacity] = useState(100);
    const [brushColor, setBrushColor] = useState('#000000');
    const [isFilling, setIsFilling] = useState(false);
    const ColorPickerRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);
    const [lastPos, setLastPos] = useState({ x: 0, y: 0 }); // Add this line

    const handleSliderChange = (event) => {
      setbrushSize(event.target.value);
    };
    const handleOpacityChange = (event) => {
      setOpacity(event.target.value);
    };
  
    // setup the canvas context
    useEffect(() => {
      let canvas = canvasRef.current;
      if (canvas) {
        let context = canvas.getContext('2d');
        setCtx(context);
      }
      currentSelectedCanvasRef.current=canvasRef.current;
    }, []);
    
  
    // handle the start of drawing
    const startDrawing = (event) => {
      
      //commented out for causing bug for not drawing on click. May cause another unintended bug.
      //the original purpose is to prevent bug with color picker. needs to be fixed to still prevent that.
      //This also somehow fixed the bug not showing layers? Investigate further. Fixed by commenting out.
      /*
      if(!ignoreToggle){
        return;
      }
        */
       if(isPickingColor){
        return;
       }
      console.log("Drawing started");
      setCtx(getActiveCtx());

      setDrawing(true);
      draw(event);
      
    }
  
    // handle the end of drawing
    const endDrawing = (event) => {
      setDrawing(false);
      //console.log("doneDrawing");
      //this check fixes a bug of an error message when cursor moves off canvas and 
      //results in a bug where the cursor keeps drawing on return to canvas on mouse up
      if(ctx !== undefined)
        ctx.beginPath();
    }

    const erase = (event, eraseNow) => {
      if(!eraseNow)
      if(!drawing) return;
      ctx.globalCompositeOperation = "destination-out";
      ctx.lineWidth = brushSize;
      ctx.lineCap = "round";
    
      // get the canvas bounding rectangle
      const rect = canvasRef.current.getBoundingClientRect();
    
      // calculate the position relative to the canvas
      // ...
      const x = (event.clientX ? event.clientX - rect.left : event.touches[0].clientX - rect.left) / zoomLevel;
      const y = (event.clientY ? event.clientY - rect.top : event.touches[0].clientY - rect.top) / zoomLevel;
  
    ctx.lineTo(x, y);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x, y);


    ///
    }

    // handle the start of erasing
const startErasing = (event) => {
  if(panModeEnabled)
    return;
  setDrawing(true);
  let eraseNow = true;
  erase(event, eraseNow);
}

// handle the end of erasing
const endErasing = (event) => {
  setDrawing(false);
  ctx.globalCompositeOperation = "source-over"; // reset to default
  ctx.beginPath();
}


  // handle the actual drawing on the canvas
  const draw = (event) => {
    if(panModeEnabled){
      return;
    }
    if(isMainBrushActive > 0){
      CustomBrush1(event);
    }
    else{
    
    //console.log("attempt draw");
    if (!drawingRef.current){
      return;
    } // Use the ref to check if drawing has started

    try{    
      ctx.lineWidth = brushSize;
      ctx.lineCap = "round";
      ctx.strokeStyle = brushColor;
      ctx.lineJoin = 'round';
      ctx.globalAlpha = Opacity / 100;
    
      // get the canvas bounding rectangle
      const rect = getActiveCtx2().getBoundingClientRect();
    
      // calculate the position relative to the canvas
      const x = (event.clientX ? event.clientX - rect.left : event.touches[0].clientX - rect.left) / zoomLevel;
      const y = (event.clientY ? event.clientY - rect.top : event.touches[0].clientY - rect.top) / zoomLevel;
      
      if(canvasJustSwapped){
        setCanvasJustSwapped(false);
      }

    
      ctx.imageSmoothingEnabled = false; // Enable anti-aliasing
      ctx.lineTo(x, y);
      ctx.stroke();
      ctx.beginPath();
      ctx.moveTo(x, y);
    }
    catch(e){
    }
  }

  }

  function CustomBrush1(event){
    if (!drawingRef.current) return;

    const ctx = canvasRef.current.getContext('2d');
  
    // get the canvas bounding rectangle
    const rect = canvasRef.current.getBoundingClientRect();

    // calculate the position relative to the canvas
    let x = (event.clientX ? event.clientX - rect.left : event.touches[0].clientX - rect.left) / zoomLevel;
    let y = (event.clientY ? event.clientY - rect.top : event.touches[0].clientY - rect.top) / zoomLevel;

    if(!drawing){
      setLastPos({ x, y }); // set the last position to the current mouse position
    }


    // adjust for the scale
    let scaleAmount = 1.0; // change this to the amount you want to zoom
    x /= scaleAmount;
    y /= scaleAmount;



    //By checking if not drawing on first frame, prevents bug from not drawing on first frame,
    //and prevents bug of drawing line from last mouse up position.
    if(!drawing || canvasJustSwapped){
      setCanvasJustSwapped(false);
      drawLine(x, y, x, y); // Modify this line
    }
    else{
      drawLine(lastPos.x, lastPos.y, x, y); // Modify this line
    }
    
    setLastPos({ x, y }); // Add this line
  }

  const [brushLog, setBrushLog] = useState(0);

  
  function drawLine(x1, y1, x2, y2) {
    let dx = x2 - x1;
    let dy = y2 - y1;
    let steps = Math.max(Math.abs(dx), Math.abs(dy));
    let xIncrement = dx / steps;
    let yIncrement = dy / steps;
  
    let x = x1;
    let y = y1;

    for (let i = 0; i <= steps; i++) {
      if(isMainBrushActive === 1){
        setPixelPercentB(Math.round(x), Math.round(y)); // Assuming setPixelBrush rounds to nearest integer
      }
      else if(isMainBrushActive === 2){
        setPixelPercent(Math.round(x), Math.round(y)); // Assuming setPixelBrush rounds to nearest integer
      }
      else if(isMainBrushActive === 3){
        setPixelPercent2(Math.round(x), Math.round(y));
          setPixelPercent3(Math.round(x), Math.round(y));
          setPixelPercent4(Math.round(x), Math.round(y));
          setPixelPercent5(Math.round(x), Math.round(y));
        }
        else if(isMainBrushActive === 4){
          setPixelPercent2(Math.round(x), Math.round(y));
          }
          else if(isMainBrushActive === 5){
              setPixelPercent3(Math.round(x), Math.round(y));
            }
            else if(isMainBrushActive === 6){
                setPixelPercent4(Math.round(x), Math.round(y));
              }
              else if(isMainBrushActive === 7){
                  setPixelPercent5(Math.round(x), Math.round(y));
                }
                else if(isMainBrushActive === 8){
                  setPixelPercent5(Math.round(x), Math.round(y));
                  setPixelPercent4(Math.round(x), Math.round(y));
                }
                else if(isMainBrushActive === 9){
                  setPixelPercent7(Math.round(x), Math.round(y));
                }
      x += xIncrement;
      y += yIncrement;
    }
  }


  function setPixelPercentB(x, y) {
    const radius = Math.floor(brushSize / 4); // Original radius
    const multiplier = 2; // Zoom effect multiplier
    const scaledRadius = radius * multiplier; // Adjust the radius based on the multiplier
    const edgeThreshold = 1 * multiplier; // Threshold for edge detection
  
    for (let i = x - scaledRadius; i <= x + scaledRadius; i += 1) {
      for (let j = y - scaledRadius; j <= y + scaledRadius; j += 1) {
        const distanceSquared = (i - x) * (i - x) + (j - y) * (j - y);
        if (distanceSquared <= scaledRadius * scaledRadius) {
          let opacity = 1;
          // Check if the pixel is near the edge of the circle for anti-aliasing
          if (distanceSquared >= (scaledRadius - edgeThreshold) * (scaledRadius - edgeThreshold)) {
            // Calculate opacity based on distance to the edge
            const distanceToEdge = Math.sqrt(distanceSquared) - (scaledRadius - edgeThreshold);
            opacity = 1 - (distanceToEdge / edgeThreshold);
          }
          // Set the fill style with adjusted opacity for anti-aliasing
          ctx.fillStyle = `rgba(${parseInt(brushColor.slice(-6, -4), 16)}, ${parseInt(brushColor.slice(-4, -2), 16)}, ${parseInt(brushColor.slice(-2), 16)}, ${opacity})`;
          ctx.fillRect(i, j, multiplier, multiplier); // Draw scaled pixel
        }
      }
    }
  }


  function setPixelPercent2(x, y) {
    const radius = Math.floor(brushSize / 4); // Original radius
    const multiplier = 2; // Zoom effect multiplier
    const scaledRadius = radius * multiplier; // Adjust the radius based on the multiplier
    ctx.fillStyle = brushColor;
  
    for (let i = x - scaledRadius; i <= x + scaledRadius; i += 1) {
      for (let j = y - scaledRadius; j <= y + scaledRadius; j += 1) {
        // Check if (i, j) is within the circle, adjusting for the zoom effect
        if (((i - x) * (i - x) + (j - y) * (j - y)) <= scaledRadius * scaledRadius) {
          // Apply the pattern condition, adjusted for the zoom effect
          if (((i / multiplier) % 2 === 1 && (j / multiplier) % 2 === 1) || ((i / multiplier) % 2 === 1 && (j / multiplier) % 2 === 1)) {
            ctx.fillRect(i, j, multiplier, multiplier); // Draw scaled pixel
          }
        }
      }
    }
  }

  function setPixelPercent3(x, y) {
    const radius = Math.floor(brushSize / 4); // Original radius
    const multiplier = 2; // Zoom effect multiplier
    const scaledRadius = radius * multiplier; // Adjust the radius based on the multiplier
    ctx.fillStyle = brushColor;
  
    for (let i = x - scaledRadius; i <= x + scaledRadius; i += 1) {
      for (let j = y - scaledRadius; j <= y + scaledRadius; j += 1) {
        // Check if (i, j) is within the circle, adjusting for the zoom effect
        if (((i - x) * (i - x) + (j - y) * (j - y)) <= scaledRadius * scaledRadius) {
          // Apply the pattern condition, adjusted for the zoom effect
          if (((i / multiplier) % 4 === 1 && (j / multiplier) % 4 === 1) || ((i / multiplier) % 4 === 1 && (j / multiplier) % 4 === 1)) {
            ctx.fillRect(i, j, multiplier, multiplier); // Draw scaled pixel
          }
        }
      }
    }
  }
  function setPixelPercent4(x, y) {
    const radius = Math.floor(brushSize / 4); // Original radius
    const multiplier = 2; // Zoom effect multiplier
    const scaledRadius = radius * multiplier; // Adjust the radius based on the multiplier
    ctx.fillStyle = brushColor;
  
    for (let i = x - scaledRadius; i <= x + scaledRadius; i += 1) {
      for (let j = y - scaledRadius; j <= y + scaledRadius; j += 1) {
        // Check if (i, j) is within the circle, adjusting for the zoom effect
        if (((i - x) * (i - x) + (j - y) * (j - y)) <= scaledRadius * scaledRadius) {
          // Apply the pattern condition, adjusted for the zoom effect
          if (((i / multiplier) % 1 === 0 && (j / multiplier) % 2 === 0) || ((i / multiplier) % 1 === 0 && (j / multiplier) % 2 === 0)) {
            ctx.fillRect(i, j, multiplier, multiplier); // Draw scaled pixel
          }
        }
      }
    }
  }

  function setPixelPercent5(x, y) {
    const radius = Math.floor(brushSize / 4); // Original radius
    const multiplier = 2; // Zoom effect multiplier
    const scaledRadius = radius * multiplier; // Adjust the radius based on the multiplier
    ctx.fillStyle = brushColor;
  
    for (let i = x - scaledRadius; i <= x + scaledRadius; i += 1) {
      for (let j = y - scaledRadius; j <= y + scaledRadius; j += 1) {
        // Check if (i, j) is within the circle, adjusting for the zoom effect
        if (((i - x) * (i - x) + (j - y) * (j - y)) <= scaledRadius * scaledRadius) {
          // Apply the pattern condition, adjusted for the zoom effect
          if (((i / multiplier) % 2 === 0 && (j / multiplier) % 1 === 0) || ((i / multiplier) % 2 === 0 && (j / multiplier) % 1 === 0)) {
            ctx.fillRect(i, j, multiplier, multiplier); // Draw scaled pixel
          }
        }
      }
    }
  }

  
  function setPixelPercent6(x, y) {
    const radius = Math.floor(brushSize / 4); // Original radius
    const multiplier = 2; // Zoom effect multiplier
    const scaledRadius = radius * multiplier; // Adjust the radius based on the multiplier
    ctx.fillStyle = brushColor;
  
    for (let i = x - scaledRadius; i <= x + scaledRadius; i += 1) {
      for (let j = y - scaledRadius; j <= y + scaledRadius; j += 1) {
        // Check if (i, j) is within the circle, adjusting for the zoom effect
        if (((i - x) * (i - x) + (j - y) * (j - y)) <= scaledRadius * scaledRadius) {
          // Apply the pattern condition, adjusted for the zoom effect
          if (((i / multiplier) % 4 === 1 && (j / multiplier) % 4 === 1) || ((i / multiplier) % 4 === 1 && (j / multiplier) % 4 === 1)) {
            ctx.fillRect(i, j, multiplier, multiplier); // Draw scaled pixel
          }
        }
      }
    }
  }

  //Final nearly bug free version of flipnote pixel brush v1
  function setPixelPercent(x, y) {
    const radius = Math.floor(brushSize / 4); // Original radius
    const multiplier = 2; // Zoom effect multiplier
    const scaledRadius = radius * multiplier; // Adjust the radius based on the multiplier
    ctx.fillStyle = brushColor;

    for (let i = x - scaledRadius; i <= x + scaledRadius; i += 1) {
      for (let j = y - scaledRadius; j <= y + scaledRadius; j += 1) {
        // Check if (i, j) is within the circle, adjusting for the zoom effect
        if (((i - x) * (i - x) + (j - y) * (j - y)) <= scaledRadius * scaledRadius) {
          // Apply the pattern condition, adjusted for the zoom effect
          if (((i / multiplier) % 2 === 1 && (j / multiplier) % 2 === 0) || ((i / multiplier) % 2 === 0 && (j / multiplier) % 2 === 1)) {
            ctx.fillRect(i, j, multiplier, multiplier); // Draw scaled pixel
          }
        }
      }
    }
  }
  function setPixelPercent7(x, y) {
    const radius = Math.floor(brushSize / 4); // Original radius
    const multiplier = 2; // Zoom effect multiplier
    const scaledRadius = radius * multiplier; // Adjust the radius based on the multiplier
    ctx.fillStyle = brushColor;
    x=x-2;
    y=y-2;

  
    if(x % 2 === 1 && y % 2 === 1){
      ctx.fillRect(x+1, y+1, multiplier, multiplier);
    }
    else if(x % 2 === 0 && y % 2 === 1){
      ctx.fillRect(x, y+1, multiplier, multiplier);
    }
    else if(x % 2 === 1 && y % 2 === 0){
      ctx.fillRect(x+1, y, multiplier, multiplier);
    }
    else if(x % 2 === 0 && y % 2 === 0){
      ctx.fillRect(x, y, multiplier, multiplier);
    }

  }

  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  let screenWidth = window.innerWidth -10;
  let screenHeight = window.innerHeight -5;
  //if(screenWidth > 700){
    screenWidth = CANVAS_WIDTH;
    screenHeight = CANVAS_HEIGHT;
  //}

  const drawingRef = useRef(drawing);

  useEffect(() => {
    // Update the ref whenever the drawing state changes
    drawingRef.current = drawing;
  }, [drawing]);

  const startAction = (event) => {
    setDrawing(true);
    drawingRef.current = true; // Update the ref immediately
    saveState();
    const rect = getActiveCtx2().getBoundingClientRect();
    let x = (event.clientX ? event.clientX - rect.left : event.touches[0].clientX - rect.left) / zoomLevel;
    let y = (event.clientY ? event.clientY - rect.top : event.touches[0].clientY - rect.top) / zoomLevel;
    let scaleAmount = 1.0; // change this to the amount you want to zoom
    x /= scaleAmount;
    y /= scaleAmount;
    setLastPos({ x, y }); // set the last position to the current mouse position
    //console.log("Action Started");
    if (isPanning) {
      console.log("the chosen pan");
      handleMouseDownPan(event);
    } else if (isErasing) {
      startErasing(event);
    } else {
      startDrawing(event);
    }
    
  };
  useEffect(() => {
    setLastPos(lastPos); // Update the last position whenever currentPos changes
  }, [lastPos]); // Dependency array, re-run the effect when currentPos changes


  const endAction = (event) => {
    setDrawing(false);
    drawingRef.current = false; // Update the ref immediately
    
    isErasing ? endErasing(event) : endDrawing(event);
  };
  ///////////////////////
  /////////////////////////
  ///////////////////////
  /////////////////////
  /////////////////
  ////////////////////////
  //This was the fix for preventing mouse up drawing failure, but causes error
  //document.addEventListener('mouseup', endAction);

  const action = (event) => {
    if (panModeEnabled) {
      handleMouseMovePan(event);
    } else if (isErasing) {
      erase(event);
    } else {
      draw(event);
    }
  };

  const handleColorChange = (event) => {
    setBrushColor(event.target.value);
  };

    // Initialize the undo and redo stacks
    const [undoStack, setUndoStack] = useState([[]]);
    const [redoStack, setRedoStack] = useState([[]]);
// Save the current state before making a brush stroke
const saveState = () => {
  const canvasState1 = canvasRef.current.toDataURL();
  const canvasState2 = canvasRef2.current.toDataURL();
  const currentUndoStack = [...undoStack];
  if (!currentUndoStack[currentIndex]) {
    currentUndoStack[currentIndex] = [];
  }
  // Push both states as a pair
  currentUndoStack[currentIndex].push([canvasState1, canvasState2]);
  setUndoStack(currentUndoStack);
  //console.log('State saved');
  //console.log('Frame:' + currentIndex);
};

// KEY PRESS
const [isUndoTriggered, setIsUndoTriggered] = useState(false);

// Function to handle undo action
const handleUndo = () => {
  if (!isUndoTriggered) {
    //console.log('Undo action triggered');
    setIsUndoTriggered(true);
    undo();
  }
};

useEffect(() => {
  const handleKeyDown = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
      event.preventDefault();
      handleUndo();
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Control' || event.key === 'Meta' || event.key === 'z') {
      setIsUndoTriggered(false);
    }
  };

  // Add event listeners
  document.addEventListener('keydown', handleKeyDown);
  document.addEventListener('keyup', handleKeyUp);

  // Cleanup function to remove event listeners
  return () => {
    document.removeEventListener('keydown', handleKeyDown);
    document.removeEventListener('keyup', handleKeyUp);
  };
}, [isUndoTriggered]); // Dependencies array includes isUndoTriggered to re-attach listeners when it changes


// KEY PRESS END

// START REDO LOGIC

const [isRedoTriggered, setIsRedoTriggered] = useState(false);

// Function to handle redo action
const handleRedo = () => {
  if (!isRedoTriggered) {
    //console.log('Redo action triggered');
    setIsRedoTriggered(true);
    // Your redo logic here
    redo();
  }
};

useEffect(() => {
  const handleKeyDown = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
      event.preventDefault();
      handleUndo();
    } else if ((event.ctrlKey || event.metaKey) && event.key === 'y') {
      event.preventDefault();
      handleRedo();
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Control' || event.key === 'Meta' || event.key === 'z' || event.key === 'y') {
      setIsUndoTriggered(false);
      setIsRedoTriggered(false);
    }
  };

  // Add event listeners
  document.addEventListener('keydown', handleKeyDown);
  document.addEventListener('keyup', handleKeyUp);

  // Cleanup function to remove event listeners
  return () => {
    document.removeEventListener('keydown', handleKeyDown);
    document.removeEventListener('keyup', handleKeyUp);
  };
}, [isUndoTriggered, isRedoTriggered]); // Update the dependencies array to include isRedoTriggered

//END REDO LOGIC


// Undo the last brush stroke
const undo = () => {
  const currentUndoStack = [...undoStack];
  const currentRedoStack = [...redoStack];
  if (currentUndoStack[currentIndex] && currentUndoStack[currentIndex].length > 0) {
    const lastStates = currentUndoStack[currentIndex].pop(); // This now contains states for both canvases
    if (!currentRedoStack[currentIndex]) {
      currentRedoStack[currentIndex] = [];
    }
    // Save current state of both canvases before undoing
    currentRedoStack[currentIndex].push([canvasRef.current.toDataURL(), canvasRef2.current.toDataURL()]);
    setUndoStack(currentUndoStack);
    setRedoStack(currentRedoStack);
    // Restore both canvases
    restoreCanvas(lastStates[0], canvasRef.current);
    restoreCanvas(lastStates[1], canvasRef2.current);
    //console.log('Undo');
  }
};

// Redo the last undone brush stroke
// Redo the last undone brush stroke
const redo = () => {
  const currentUndoStack = [...undoStack];
  const currentRedoStack = [...redoStack];
  if (currentRedoStack[currentIndex] && currentRedoStack[currentIndex].length > 0) {
    const lastStates = currentRedoStack[currentIndex].pop(); // This now contains states for both canvases
    if (!currentUndoStack[currentIndex]) {
      currentUndoStack[currentIndex] = [];
    }
    // Save current state of both canvases before redoing
    currentUndoStack[currentIndex].push([canvasRef.current.toDataURL(), canvasRef2.current.toDataURL()]);
    setUndoStack(currentUndoStack);
    setRedoStack(currentRedoStack);
    // Restore both canvases
    restoreCanvas(lastStates[0], canvasRef.current);
    restoreCanvas(lastStates[1], canvasRef2.current);
    //console.log('Redo');
  }
};

// Restore the canvas to a previous state
const restoreCanvas = (dataUrl, canvas) => {
  const image = new Image();
  image.src = dataUrl;
  image.onload = () => {
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(image, 0, 0);
  }
};

const [isPickingColor, setIsPickingColor] = useState(false);
const [ignoreToggle, setIgnoreToggle] = useState(false);

const toggleColorPicker = () => {
  
};

const handleMouseDown = () => {
  // Set the flag to temporarily disable toggling
  setIgnoreToggle(true);
};

// Use onMouseUp to reset the flag if needed
const handleMouseUp = () => {
  toggleColorPicker();
  setIgnoreToggle(false);
  if(isPickingColor){
    setIsPickingColor(false);
    
  }
  setIsPickingColor(!isPickingColor);
  //console.log("ran");
};

const pickColor = (event) => {
  if (event.target === canvasRef.current) {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const pixel = ctx.getImageData(x, y, 1, 1);
    const data = pixel.data;
    // Check if the alpha value is 0 (fully transparent)
    if (data[3] === 0) {
      // Set brush color to white
      setBrushColor('#FFFFFF');
    } else {
      const rgba = `rgba(${data[0]}, ${data[1]}, ${data[2]}, ${data[3] / 255})`;
      setBrushColor(rgbaToHex(rgba));
    }
    setIsPickingColor(false); // turn off color picker mode after picking color
  } else {
    setIsPickingColor(true);
    if (event.target === ColorPickerRef.current) {
      setIsPickingColor(true);
    }
    return;
  }
};

const rgbaToHex = (rgba) => {
  if (!rgba || !rgba.startsWith('rgba')) {
    return '#000000'; // default color if rgba string is not in expected format
  }
  const parts = rgba.substring(rgba.indexOf('(')).split(',');
  const r = parseInt(parts[0].substring(1).trim(), 10);
  const g = parseInt(parts[1].trim(), 10);
  const b = parseInt(parts[2].trim(), 10);
  const hex = ((r << 16) | (g << 8) | b).toString(16);
  return '#' + ('000000' + hex).slice(-6);
};

useEffect(() => {


  if (isPickingColor) {
    document.body.style.cursor = 'crosshair';
    setTimeout(() => {
      document.addEventListener('mousedown', pickColor);
    }, 0);
  } else {
    document.body.style.cursor = 'default';
    document.removeEventListener('mousedown', pickColor);
  }

  return () => {
    document.removeEventListener('mousedown', pickColor);
  };
}, [isPickingColor]);

const [isMainBrushActive, setIsMainBrushActive] = useState(3);
const toggleMainBrush = (brushNumber) => {
  setIsMainBrushActive(brushNumber);
  if(brushNumber === 0){
    setIsErasing(true);
  }
  else{
    setIsErasing(false);
  }
};

const [isCanvasSwapped, setIsCanvasSwapped] = useState(false);
const [canvasJustSwapped, setCanvasJustSwapped] = useState(false);
const swapCanvas = () => {
  setIsCanvasSwapped(!isCanvasSwapped);
  setCanvasJustSwapped(true);
  currentSelectedCanvasRef.current = isCanvasSwapped ? canvasRef.current : canvasRef2.current;
  setCtx(getActiveCtx());
  console.log("swapped!");
  console.log();
};

const swapCanvasLayer1 = () => {
  setIsCanvasSwapped(false);
  setCanvasJustSwapped(true);
  currentSelectedCanvasRef.current = canvasRef.current;
  setCtx(getActiveCtx());
  console.log("swapped!");
  console.log();
};
const swapCanvasLayer2 = () => {
  setIsCanvasSwapped(true);
  setCanvasJustSwapped(true);
  currentSelectedCanvasRef.current = canvasRef2.current;
  setCtx(getActiveCtx());
  console.log("swapped!");
  console.log();
};

const getActiveCtx = () => {
  
  return currentSelectedCanvasRef.current.getContext('2d');
};

const getActiveCtx2 = () => {
  return currentSelectedCanvasRef.current ;
};

// Assuming this is within your component
const [zoomLevel, setZoomLevel] = useState(1); // Start with no zoom

const zoomIn = () => {
  if(zoomLevel < 2.5)
  setZoomLevel(zoomLevel + 2); // Increase zoom by 10%
  position.x = 0 ;
  position.y = 0;


};

const zoomOut = () => {
  if(zoomLevel > 1)
    setZoomLevel(zoomLevel - 2); // Decrease zoom by 10%
  position.x = 0;
  position.y = 0;

};

useEffect(() => {
  const handleWheel = (event) => {
    if (event.deltaY < 0) {
      zoomIn();
    } else if (event.deltaY > 0) {
      zoomOut();
    }
  };

  // Assuming 'canvasContainerRef' is a ref to your canvas container
  const canvasContainer = canvasContainerRef.current;
  canvasContainer.addEventListener('wheel', handleWheel);

  // Cleanup
  return () => {
    canvasContainer.removeEventListener('wheel', handleWheel);
  };
}, [zoomIn, zoomOut]); // Make sure to include dependencies here

const [isPanning, setIsPanning] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  
  // State to track if pan mode is enabled
const [panModeEnabled, setPanModeEnabled] = useState(false);

// Function to toggle pan mode
const togglePanMode = () => {
  setPanModeEnabled(!panModeEnabled);
};

// Adjusted event handlers
const handleMouseDownPan = (e) => {
  if (!panModeEnabled) return; // Only proceed if pan mode is enabled
  setIsPanning(true); // Initiate panning

  // Determine if the event is a touch event and use the appropriate clientX and clientY
  const clientX = e.touches ? e.touches[0].clientX : e.clientX;
  const clientY = e.touches ? e.touches[0].clientY : e.clientY;

  // Adjust the start position based on the current position of the canvas
  // Use the `position` state instead of `currentPos`
  const adjustedStartPos = {
    x: clientX - position.x,
    y: clientY - position.y,
  };
  setStartPos(adjustedStartPos); // Update startPos for calculating new position
};

const handleMouseMovePan = (e) => {
  if (!isPanning) return; // Only pan if isPanning is true

  // Determine if the event is a touch event and use the appropriate clientX and clientY
  const clientX = e.touches ? e.touches[0].clientX : e.clientX;
  const clientY = e.touches ? e.touches[0].clientY : e.clientY;

  let deltaX = clientX - startPos.x;
  let deltaY = clientY - startPos.y;

  let adjustedZoom = zoomLevel;
  if (zoomLevel === 1) {
    adjustedZoom = 0;
  }
  let limit = 50 * adjustedZoom;
  // Clamp deltaX and deltaY to be within -50 to 50
  deltaX = Math.max(-limit, Math.min(deltaX, limit));
  deltaY = Math.max(-limit, Math.min(deltaY, limit));

  const newPos = { x: deltaX, y: deltaY };
  setPosition(newPos);
  canvasContainerRef.current.style.transform = `scale(${zoomLevel}) translate(${newPos.x}px, ${newPos.y}px)`;
};

const handleMouseUpPan = () => {
  setIsPanning(false);
};



  return (
    <>
        <div ref={newRef}></div>
        <div className="canvas-mask">
        <div className="canvas-container" ref={canvasContainerRef}
        style={{ transform: `scale(${zoomLevel})`, transformOrigin: 'top' ,cursor: isPanning ? 'grabbing' : 'default' }}
        onMouseDown={handleMouseDownPan}
        onMouseMove={handleMouseMovePan}
        onMouseUp={handleMouseUpPan}
        onMouseLeave={handleMouseUpPan}

        onTouchStart={handleMouseDownPan} 
        onTouchEnd={handleMouseUpPan} 
        onTouchMove={handleMouseMovePan} 
            >
          
        <canvas className="canvas-bg" ref={CanvasBackground} width={CANVAS_WIDTH} height={CANVAS_HEIGHT}></canvas>
          <canvas 
            className="canvas-main"
            ref={canvasRef} 
            onMouseDown={startAction} 
            onMouseUp={endAction} 
            onMouseMove={action} 
            onTouchStart={startAction} 
            onTouchEnd={endAction} 
            onTouchMove={action} 
            width={screenWidth}
            height={screenHeight}
          />
          <canvas 
            className="canvas-layer-2"
            ref={canvasRef2} 
            onMouseDown={startAction} 
            onMouseUp={endAction} 
            onMouseMove={action} 
            onTouchStart={startAction} 
            onTouchEnd={endAction} 
            onTouchMove={action} 
            width={screenWidth}
            height={screenHeight}
          />
          <canvas 
            ref={Onion1} 
            className="canvas-onion-1" 
            width={CANVAS_WIDTH} 
            height={CANVAS_HEIGHT} 
            style={{ display: isAnimationPlaying ? 'none' : 'block' }}
          ></canvas>
          <canvas ref={Onion2} className="canvas-onion-2" width={CANVAS_WIDTH} height={CANVAS_HEIGHT}
          style={{ display: isAnimationPlaying ? 'none' : 'block' }}></canvas>
          <canvas ref={Onion3} className="canvas-onion-3" width={CANVAS_WIDTH} height={CANVAS_HEIGHT}
          style={{ display: isAnimationPlaying ? 'none' : 'block' }}></canvas>
          <canvas ref={Onion4} className="canvas-onion-4" width={CANVAS_WIDTH} height={CANVAS_HEIGHT}
          style={{ display: isAnimationPlaying ? 'none' : 'block' }}></canvas>
        </div>
        </div>
        <div className="canvas-tools">
        {/*Displays brush size, and renders white space if below 10 before the number */}
        <label>Brush Size: {brushSize < 10 ? `${brushSize}    ` : brushSize}</label>
        <input 
          type="range" 
          min="1" 
          max="50" 
          value={brushSize} 
          onChange={handleSliderChange} 
          className="slider" 
          id="myRange"
          disabled={isAnimationPlaying}
        />
        <br/>
        
        {/*
        <input 
          type="range" 
          min="1" 
          max="100" 
          value={Opacity} 
          onChange={handleOpacityChange} 
          className="slider" 
          id="myRange"
        />
        <p>Opacity: {Opacity}</p>

        <button onClick={() => setIsFilling(!isFilling)}>
            {isFilling ? 'Switch to Draw' : 'Switch to Fill'}
        </button>

        */}
        <input 
        type="color" 
        value={brushColor} 
        onChange={handleColorChange} 
        id="myColor"
        disabled={isAnimationPlaying}
      />
      
      <button className="btn-outline btn-small" ref={ColorPickerRef} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} disabled={isAnimationPlaying} 
      style={{ backgroundColor: isPickingColor ? '#a8b8ff' : '' }}>
        
        {/* {isPickingColor ? 'Cancel picking color' : 'Pick Color'}*/}
        
        <img className="icon-large" src={IMG_ColorPicker} alt="Color Picker" />
        
      </button>
      <button className="pre-color-select" value="#111111" onClick={handleColorChange} style={{ backgroundColor: '#111111' }} disabled={isAnimationPlaying}></button>
      <button className="pre-color-select" value="#FFFFFF" onClick={handleColorChange} style={{ backgroundColor: '#FFFFFF' }} disabled={isAnimationPlaying}></button>
      <button className="pre-color-select" value="#FF2A2A" onClick={handleColorChange} style={{ backgroundColor: '#FF2A2A' }} disabled={isAnimationPlaying}></button>
      <button className="pre-color-select" value="#0A39FF" onClick={handleColorChange} style={{ backgroundColor: '#0A39FF' }} disabled={isAnimationPlaying}></button>
      <button className="pre-color-select" value="#058b2d" onClick={handleColorChange} style={{ backgroundColor: '#058b2d' }} disabled={isAnimationPlaying}></button>
      <button className="pre-color-select" value="#fff102" onClick={handleColorChange} style={{ backgroundColor: '#fff102' }} disabled={isAnimationPlaying}></button>
      <br/>
      <div className="group-container">
        <div className="undo-redo-container">
          <div className="layer-container">
            <button onClick={swapCanvasLayer1}  disabled={isAnimationPlaying} className="layer-button"
            style={{ backgroundColor: !isCanvasSwapped ? '#c3cfff' : '' }}>Layer 1</button>
            <button onClick={swapCanvasLayer2}  disabled={isAnimationPlaying} className="layer-button"
            style={{ backgroundColor: isCanvasSwapped ? '#c3cfff' : '' }}>Layer 2</button>
          </div>
          <button className="button-2-test" onClick={undo} disabled={isAnimationPlaying} style={{display:"inline-flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
              <img style={{height:"24px", width:"24px"}} src={IMG_Undo} alt="Undo" />
              Undo
            </button>
          <button className="button-2-test" onClick={redo} disabled={isAnimationPlaying} style={{display:"inline-flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
            <img style={{height:"24px", width:"24px"}} src={IMG_Redo} alt="Redo" />
            Redo
          </button>
        </div>
      </div>
      {/* 
      <button onClick={() => setIsFilling(!isFilling)} disabled={isAnimationPlaying}>
        {isFilling ? 'Fill Off' : 'Fill On'}
      </button>
      */}
      <button onClick={() => toggleMainBrush(0)} disabled={isAnimationPlaying}
      className="brush-button"
      style={{ borderColor: isMainBrushActive === 0  ? '#dd00ff' : '', height:'30px', width:'30px'}}>
      <img src={IMG_Eraser} style={{height:"26px", width:"26px"}}/>
    </button>
      <button onClick={() => toggleMainBrush(3)} disabled={isAnimationPlaying}
      className="brush-button"
      style={{ borderColor: isMainBrushActive === 3  ? '#0033ff' : '', height:'30px', width:'30px' }}>
        <img src={IMG_Brush_Pixel} style={{height:"26px", width:"26px"}}/>
      </button>
      <button onClick={() => toggleMainBrush(5)} disabled={isAnimationPlaying}
      className="brush-button"
      style={{ borderColor: isMainBrushActive === 5  ? '#0033ff' : '', height:'30px', width:'30px' }}>
        <img src={IMG_Brush_10} style={{height:"26px", width:"26px"}}/>
      </button>
      <button onClick={() => toggleMainBrush(4)} disabled={isAnimationPlaying}
      className="brush-button"
      style={{ borderColor: isMainBrushActive === 4  ? '#0033ff' : '', height:'30px', width:'30px' }}>
        <img src={IMG_Brush_20} style={{height:"26px", width:"26px"}}/>
      </button>
      <button onClick={() => toggleMainBrush(2)}  disabled={isAnimationPlaying}
      className="brush-button"
      style={{ borderColor: isMainBrushActive === 2  ? '#0033ff' : '', height:'30px', width:'30px' }}>
        <img src={IMG_Brush_50} style={{height:"26px", width:"26px"}}/>
      </button>
      <button onClick={() => toggleMainBrush(8)} disabled={isAnimationPlaying}
      className="brush-button"
      style={{ borderColor: isMainBrushActive === 8  ? '#0033ff' : '', height:'30px', width:'30px' }}>
        <img src={IMG_Brush_80} style={{height:"26px", width:"26px"}}/>
      </button>
      <button onClick={() => toggleMainBrush(6)} disabled={isAnimationPlaying}
      className="brush-button"
      style={{ borderColor: isMainBrushActive === 6  ? '#0033ff' : '', height:'30px', width:'30px' }}>
        <img src={IMG_Brush_Horizontal} style={{height:"26px", width:"26px"}}/>
      </button>
      <button onClick={() => toggleMainBrush(7)} disabled={isAnimationPlaying}
      className="brush-button"
      style={{ borderColor: isMainBrushActive === 7  ? '#0033ff' : '', height:'30px', width:'30px' }}>
        <img src={IMG_Brush_Vertical} style={{height:"26px", width:"26px"}}/>
      </button>
      <button onClick={() => toggleMainBrush(9)} disabled={isAnimationPlaying}
      className="brush-button"
      style={{ borderColor: isMainBrushActive === 9  ? '#0033ff' : '', height:'30px', width:'30px'}}>
        <img src={IMG_Pixel_Brush} style={{height:"26px", width:"26px"}}/>
      </button>
      <button className="brush-button" onClick={() => toggleMainBrush(1)}  disabled={isAnimationPlaying}
      style={{ borderColor: isMainBrushActive === 1  ? '#0033ff' : '', height:'30px', width:'30px' }}>
        <img src={IMG_Brush_AA} style={{height:"26px", width:"26px"}}/>
      </button>

      {/* 
      <button onClick={swapCanvas}
      style={{ backgroundColor: isCanvasSwapped ? '#c3cfff' : 'transparent' }}>Toggle Layers</button>
      */}
      <div>
        <button onClick={zoomIn} className="button-1-test" disabled={isAnimationPlaying}>
          <img style={{height:"24px", width:"24px"}} src={IMG_ZoomIn} alt="zoom in" />
        </button>
        <button onClick={zoomOut} className="button-1-test" disabled={isAnimationPlaying}>
          <img style={{height:"24px", width:"24px"}} src={IMG_ZoomOut} alt="zoom out" />
        </button>
        <button onClick={togglePanMode} className="button-1-test"  disabled={isAnimationPlaying}
        style={{ borderColor: panModeEnabled  ? '#ff0000' : '#000000'  ,backgroundColor: panModeEnabled  ? '#ff00002b' : ''}}>
          <img style={{height:"24px", width:"24px"}} src={IMG_Pan} alt="pan" />
        </button>
      </div>
        <div className={`controls-container`}>
          <CanvasManagment drawingCanvasRef={canvasRef} canvasContainerRef={canvasContainerRef}
          newRef={newRef} Onion1={Onion1} Onion2={Onion2} Onion3={Onion3} Onion4={Onion4} 
          CANVAS_WIDTH = {CANVAS_WIDTH} CANVAS_HEIGHT = {CANVAS_HEIGHT} 
          isAnimationPlaying={isAnimationPlaying} setIsAnimationPlaying={setIsAnimationPlaying} 
          currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} zoomOut={zoomOut} canvasRef2={canvasRef2}
          />
        </div>
        </div>
    </>
  )
}
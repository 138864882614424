import logo from './logo.svg';
import './App.css';
import FFMPEGPlayground from './FFMPEGPlayground';
import SiteExample from './SiteExample5';
//import DrawingTest from './DrawingTest';
import DrawingTest2 from './DrawingTest2';
import DrawingTest3 from './DrawingTest3';
import DrawingTest4 from './DrawingTest4';
import DrawingTest5 from './DrawingTest5';
import DrawingTest6 from './DrawingTest6';
import DrawingTest7 from './DrawingTest7';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//import BrushTest from './BrushTest';

import CanvasDrawing from './components/CanvasDrawing';
import VideoConversion from './components/VideoConversion';
import ButtonToggleCount from './components/ButtonToggleCount';
import UITesting from './components/UITesting';
import DevBlog from './DevBlog';
import Licensing from './Licensing';

import React, { useEffect } from 'react';
import { analytics } from './FirebaseConfig'; // Adjust the path as necessary
import { logEvent } from "firebase/analytics";
import KnownBugs from './KnownBugs';


function App() {

  useEffect(() => {
    // Log a custom event
    logEvent(analytics, 'notification_received');
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
        <Route path="/devblog" element={<DevBlog />} />
          <Route path="/licensing" element={<Licensing/>} />
          <Route path="/known-bugs" element={<KnownBugs/>} />
          <Route path="/" element={<CanvasDrawing />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

      {/*
      <BrushTest/>
      <DrawingTest2/>
<UITesting/>
    //Zoom test
    <DrawingTest6/>
    //Line test
    <DrawingTest5/>
    //fill test
    <DrawingTest4/>
      <DrawingTest3/>
      
      <DrawingTest/>
      <DrawingTest/>
      <ButtonToggleCount/>
      */}
/*



Features I want to add:

- make ui/ux nicer / prettier
- add 1 extra layer to draw on
- add home page with draw, dev blog, licenses, version number
- add devblog page with updates, and notes

Bugs:
- Refer to bug tracking sheet in google docs


Implement later:
- ADD THE TEMP CANVAS DRAW FUNCTIONALITY (for drawing shapes, lines, opacity, ect.)
- add layers
- draw line
- line snap toggle for mobile
- zoom in / out
- fill bucket tool


Later Features:
- Add shrink / grow buttons (resize)
 (delete, insert, flip, clear, and play - keystroke shortcuts) 
- ms spray tool
- brush transparency
- toggle for stop at music end / frame end
- view all frames
- visual animation for changing a frame
- save functionality
- save as gif
- change brush type
- upload multiple sounds
- some sort of sound editor
- select tool
- gradient tool (linear gradient, circular gradient)
- draw square
- draw circle
- have a setting to change the default drawing background color
- type text
- pan tool
- different resolutions. stick with square to start.
- grid line background toggle
- allow transparent background for some works
- pixel art support
.ogg / .wav support for audio files
- use custom uploaded brushes
- Add full control rotation flip
- Add blur filter
- brush softness / hardness / blur
- add warning for unsaved work
- invert colors
- offscreen canvas vs camera view canvas (Have 2 canvases for this? We render what is on the main canvas? Outer canvas is big?)
- Contests section on title screen. Show ongoing contests, and past winners.

- Auto saving (With linked account / local storage on app)
- directional onion skin
- allow file uploads such as pngs from pc and mobile, to be used in a reference layer. Reference layer will not render.




Start screen is:
Ready? 
(volume button)
('Let's begin!' button)

Updates;
(The Tools Update)
(The Save Progress Update) (export project as zip, named pngs, mp3, config file)
*Beta Releases*
(The Frame Viewer Update)
(The Sound Editor Update)
(The Format Support Update) (gif, ogg, wav, export video formats, download png sheet, download single pngs)
(The Android and IOS update)
(Contests Update)
(Themes Update)


We will have no mandatory ads, we will have no mandatory watermarks. No purchase / subscription needed for basic animation features.

Added:

5/29/2024
- onion skin toggle and how many frames to show "Trace" (No canvas functionality yet)
- copy frames
- clear canvas button
- skip to first frame / last frame
- copy / paste
- insert between frames
- delete frames
- flip canvas side to side, or up and down
- play current animation before rendering (Show current frame as it plays)
- toggle looping animation in preview mode
- download mp4 on render click
- change brush size
- eraser tool
- change brush color
- undo / redo button

6/2/2024:
- added basics of onion skinning
- fixed bug of drawing continuing when mouse lift off canvas
- fixed bug of drawing continuing on page highlight

6/3/2024:
- color picker
- transparent brush has problems. Pushing off implementation for now.
- multiple pre-selected colors

6/5/24:
- Experimented and created pixel brushes, recreated 2 Flipnote style patterns for brushes

6/7/24:
- Experimented more with pixel brushes, added proper zoom scale and logic for new stripes brush
- Experimented with blurs, and accidentally found a glow effect. Blurs still need more research.
- Add a 180 degree flip button

6/10/24:
- Research on competition, and how all the good beginner apps are pay walled to heck. I am needed more than ever.
- onion skin functionality completed
- fixed bug on render of missing white background, not that canvas that is drawn on doesn't have transparency 
- fixed eraser by adding an under layer that can't be edited
- fixed animation play missing frame
- added adjustable height and width to entire flow of app
- basic music controls added. needs restructuring.
- fixed onion skin render glitch upon creating new frame

6/22/24:
- Forgot to include updates on past days. oops.
- Implemented a working paint fill tool in playground without a seam
- fix off the screen continue drawing bug
- added basic line draw capability with shift key snap to 15 degree increments. Temp layer needed for proper drawing.

6/23/24:
- Implemented keystroke shortcuts for copy, paste, undo, redo
- Started zoom and pan test environment
- Got zoom and dimensions and export working in a lossless format, honestly didn't think I could.

7/8/24:
- fixed loop stuck glitch
- play music on preview
- added basic mobile responsiveness 
- pushed app to live site for testing
- added pause button
- fixed glitch of erase screen turning the screen white, messing with onion skin
- fixed glitch of delayed start on play
- disabled onion skin on play
- disabled parent component controls while playing
- disabled frame jump buttons at beginning / end of array
- got colors to be visually disabled on play
- added extend video beyond audio limit if audio is shorter than video length

7/10/24:
- fixed clicking on color picker brush again to de select it
- fixed no drawing on touch and not moving
- added video progress download bar
- fixed major undo glitches. Only minor glitches left.
- fixed video progress bar not going away on render complete





Supporter streak

Help support us by watching an ad every day / month for a supporter streak. Unlock special profile picture frames and themes.
Create free themes for tools

Galaxy motion theme on / off
Light
Dark
Pastel
Retro flipnote
Windows classic
Rustic western / country theme
Run down abandoned factory theme
College ruled paper pencil sketch UI





- Doodle related! Important word!
Ani-doodle?
Doodle Animate?
Flip Doodle
Doodle Studio
Flipa'a Doodle!

If we can't find a word that goes with doodle that is an animation like word, we might have to
choose a word that goes along with it. Like doodle home, or doodle cafe.

DoodleScribble, sketch
DoodleFlick
DoodlePix
DoodleSketch
DoodlePlay
DoodleToon - taken
Project Doodle
Toon
Doodle mate studio
*/


import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { Link } from 'react-router-dom';

// fetchFile function
function fetchFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = event => {
        const buffer = event.target.result;
        resolve(new Uint8Array(buffer));
      };
      reader.onerror = error => reject(error);
      reader.readAsArrayBuffer(file);
    });
}

// fetchURL function
async function fetchURL(url) {
    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    return new Uint8Array(buffer);
}

// toBlobURL function
function toBlobURL(content, mimeType) {
  return URL.createObjectURL(new Blob([content], { type: mimeType }));
}
// Function Start
export default function VideoConversion({saveCanvasFinal, canvasManagmentRef, frameRate, CANVAS_HEIGHT, CANVAS_WIDTH, onAudioRefChange, newAudioRef, newFile, isAnimationPlaying}) {
    const [loaded, setLoaded] = useState(false);
    const ffmpegRef = useRef(new FFmpeg());
    const videoRef = useRef(null);
    const canvasRef = canvasManagmentRef;
    const [file, setFile] = useState(null);
    const [audioURL, setAudioURL] = useState("");
    const audioRef = useRef(null); // Create a ref for the audio element
    const messageRef = useRef(null);
    const [isRendering, setIsRendering] = useState(false);

    const onFileChange = event => {
      setFile(event.target.files[0]);
  
      // Read the file as a data URL and set it as the audio source
      const reader = new FileReader();
      reader.onloadend = () => {
        setAudioURL(reader.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    };
    
    const load = async () => {
        const ffmpeg = ffmpegRef.current;
        ffmpeg.on('log', ({ message }) => {
          messageRef.current.innerHTML = message;
        });
        await ffmpeg.load({
        });
        setLoaded(true);
        setIsRendering(true);
        transcode();
    } 

    function isImageData(obj) {
      return obj instanceof ImageData || 
             (obj && typeof obj.width === 'number' && 
              typeof obj.height === 'number' && 
              obj.data instanceof Uint8ClampedArray);
  }


    const transcode = async () => {
        
        //Save last frame
        const newCanvases = await saveCanvasFinal();
        //console.log("Rendering number of frames: " + newCanvases.length);
        const ffmpeg = ffmpegRef.current;

// Calculate total time in seconds
const totalTimeInSeconds = newCanvases.length / frameRate;
// Calculate minutes and seconds
const minutes = Math.floor(totalTimeInSeconds / 60);
const seconds = Math.round(totalTimeInSeconds % 60);
// Format and set the total render time
setTotalRenderTime(`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);

    // Load the audio file if it exists
    let audioFile;
    //console.log("New File: " + newFile);
    if (newFile && newFile instanceof Blob) {
        try {
            audioFile = await fetchFile(newFile);
            // Write the audio file to the FFmpeg filesystem
            await ffmpeg.writeFile('input.mp3', audioFile);
        } catch (error) {
            //console.log('No audio file found');
        }
    }
    
        // Array of all drawn images loaded
        let imageBlobs = [];
    
        const canvas = document.createElement('canvas');
        canvas.width = CANVAS_WIDTH;
        canvas.height = CANVAS_HEIGHT;
        //console.log("FINAL LENGTH CHECK" + newCanvases.length);
        //console.log("CANVAS CHECK: " + canvasManagmentRef);
        for (let i = 0; i < newCanvases.length; i++) {
            const canvas = canvasRef.current;
            if (!canvas) {
              console.error('Canvas not found');
              return;
            }
            console.log(newCanvases);
            const ctx = canvas.getContext('2d');
            // Clear the current canvas
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            
            // Restore the saved state of the canvas
            if (newCanvases[0]) {
              //* TEMP FIX -- This is updated code, it merges the layers together to render the video.
              ctx.fillStyle = "white";
              ctx.fillRect(0, 0, canvas.width, canvas.height);
              
              // Create a temporary canvas
              let tempCanvas = document.createElement('canvas');
              tempCanvas.width = canvas.width;
              tempCanvas.height = canvas.height;
              
              // Draw the image data onto the temporary canvas
              let tempCtx = tempCanvas.getContext('2d');
              console.log(newCanvases[i]);
              if (isImageData(newCanvases[i][0])) {
                
                tempCtx.putImageData(newCanvases[i][1], 0, 0);
                
                ctx.drawImage(tempCanvas, 0, 0);

                const offscreenCanvas = document.createElement('canvas');
                offscreenCanvas.width = newCanvases[i][0].width;
                offscreenCanvas.height = newCanvases[i][0].height;
                const offscreenCtx = offscreenCanvas.getContext('2d');
                offscreenCtx.putImageData(newCanvases[i][0], 0, 0);
                ctx.drawImage(offscreenCanvas, 0, 0);

            } else {
                console.error('newCanvases[i] is not of type ImageData');
            }
              
              // Draw the temporary canvas onto the original canvas
              
            } else {
              console.error('No saved canvas data found');
            }
            imageBlobs.push(await new Promise(resolve => canvasRef.current.toBlob(resolve)));
        }

        let doShortest = true;
        //Do calculations for audio length vs video length
        if (newFile) {
          // Create a temporary URL for the uploaded file
          const url = URL.createObjectURL(newFile);
          const audio = new Audio(url);
        
          // Load the audio and get its duration
          audio.onloadedmetadata = () => {
            //console.log('Music Duration:', audio.duration, 'seconds');
            // Clean up the temporary URL after getting the duration
            URL.revokeObjectURL(url);
        
            // Assuming frameRate and newCanvases are defined elsewhere in your code
            //console.log('Video Duration:', frameRate * newCanvases.length, 'seconds');
            if(frameRate * newCanvases.length > audio.duration)
            {
              //console.log("Video is longer than audio");
              doShortest = false;
            }
          };
        }
    
        for (let i = 0; i < imageBlobs.length; i++) {
            let imageName = `frame${i}.png`;
            //console.log(imageName);
            await ffmpeg.writeFile(imageName, new Uint8Array(await imageBlobs[i].arrayBuffer()));
        }
    
        // Create a video from the image sequence without audio
        await ffmpeg.exec(['-framerate', frameRate.toString(), '-i', 'frame%d.png', '-c:v', 'libx264', '-r', '30', '-pix_fmt', 'yuv420p', 'output_without_audio.mp4']);
        
        let outputFileName = 'output_without_audio.mp4';
    
        // If audio file exists, add the audio to the video
        if (newFile && newFile.name) {
          const command = ['-i', 'output_without_audio.mp4'];
      
          if (newFile.name.endsWith('.mp4')
          || newFile.name.endsWith('.m4a')
          || newFile.name.endsWith('.mov')
          || newFile.name.endsWith('.wmv')
          || newFile.name.endsWith('.avi')) {
              // Extract audio from the uploaded .mp4 file
              const audioExtractCommand = ['-i', 'input.mp3', '-q:a', '0', '-map', 'a', 'extracted_audio.mp3'];
              await ffmpeg.exec(audioExtractCommand);
              command.push('-i', 'extracted_audio.mp3');
          } else {
              command.push('-i', 'input.mp3');
          }
      
          command.push('-c:v', 'copy', '-c:a', 'aac', '-b:a', '256k');
          // if (doShortest) {
          command.push('-shortest');
          // }
          command.push('output.mp4');
      
          await ffmpeg.exec(command);
      
          outputFileName = `output.mp4`;
      }
    
        // Read the output file and set it as the video source
        const data = await ffmpeg.readFile(outputFileName);
        videoRef.current.src = URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }));
        downloadVideo();
        setIsRendering(false);
        
    };
    function downloadVideo() {
      // Create a new link element
      const link = document.createElement('a');
    
      // Get the URL of the video
      const url = videoRef.current.src;
    
      // Set the link's href to the video's URL
      link.href = url;
    
      // Set the download attribute of the link to the desired file name
      link.download = 'converted-video.mp4';
    
      // Append the link to the body
      document.body.appendChild(link);
    
      // Simulate a click on the link
      link.click();
    
      // Remove the link from the body
      document.body.removeChild(link);
      setIsRendering(false);
      setTimeValue('00:00:00:00');
    }

    const onPlayFromStart = () => {
      if (audioRef.current) {
        audioRef.current.currentTime = 0; // Reset playback position
        audioRef.current.play(); // Start playback
      }
    };

    const onStop = () => {
      if (audioRef.current) {
        audioRef.current.pause(); // Stop playback
        audioRef.current.currentTime = 0; // Reset playback position
      }
    };


    useLayoutEffect(() => {
      if (onAudioRefChange) {
        onAudioRefChange.current = audioRef.current;
      }
    }, [audioRef, onAudioRefChange]);


      // State to hold the extracted time value
  const [timeValue, setTimeValue] = useState('');

  const [message, setMessage] = useState('');

  // Set up a mutation observer to listen for changes in the <p> tag
  useEffect(() => {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList' || mutation.type === 'characterData') {
          const newMessage = messageRef.current.innerHTML;
          setMessage(newMessage); // Update the state with the new innerHTML
        }
      });
    });

    const config = { childList: true, characterData: true, subtree: true };
    observer.observe(messageRef.current, config);

    // Clean up the observer to prevent memory leaks
    return () => observer.disconnect();
  }, []);

  // useEffect that reacts to changes in the message state
  useEffect(() => {
    //console.log("Message: " + message);

    // Extract the time value using a regular expression
    const timeRegex = /time=([0-9:.]+)/;
    const match = message.match(timeRegex);

    if (match) {
      // Update the state with the extracted time value
      setTimeValue(match[1]);
    }
  }, [message]); // Depend on the message state

  
  const [totalRenderTime, setTotalRenderTime] = useState('');

  const convertTimeToSeconds = (timeStr) => {
    const parts = timeStr.split(':').map(Number);
    let seconds = 0;
    if (parts.length === 4) { // Assuming format is HH:MM:SS:FF (FF for frames)
      // Assuming 25 frames per second as a standard
      seconds = parts[0] * 3600 + parts[1] * 60 + parts[2] + parts[3] / 25;
    } else if (parts.length === 2) { // Assuming format is MM:SS
      seconds = parts[0] * 60 + parts[1];
    }
    return seconds;
  };
  
  const timeValueSeconds = convertTimeToSeconds(timeValue);
  const totalRenderTimeSeconds = convertTimeToSeconds(totalRenderTime);
  const progress = timeValueSeconds / totalRenderTimeSeconds * 100;

  function convertTimeToSeconds2(timeStr) {
    let parts = timeStr.split(':').reverse();
    let seconds = 0;
  
    // Convert each part of the time string to seconds
    // parts[0] = seconds, parts[1] = minutes, parts[2] = hours, parts[3] = milliseconds (ignored in calculation)
    if (parts[0]) seconds += parseInt(parts[0]); // Seconds
    if (parts[1]) seconds += parseInt(parts[1]) * 60; // Minutes to seconds
    if (parts[2]) seconds += parseInt(parts[2]) * 3600; // Hours to seconds
  
    return seconds;
  }
  
  function calculateProgress(time1, time2) {
    const time1InSeconds = convertTimeToSeconds2(time1);
    const time2InSeconds = convertTimeToSeconds2(time2);
  
    // Calculate the progress percentage
    const progress = (time1InSeconds / time2InSeconds) * 100;
  
    return progress.toFixed(2); // Return the progress percentage with 2 decimal places
  }

  function TimeConverter(time1, time2) {
    // Convert both time strings to seconds
    //console.log(time1);
    //console.log(time2);
    const time1InSeconds = convertTimeToSeconds2(time1);
    const time2InSeconds = convertTimeToSeconds2(time2);
    const progress = (time1InSeconds / time2InSeconds) * 100;
    return progress.toFixed(2); // Return the progress percentage with 2 decimal places
  }

  const toggleRendering = () => {
    setIsRendering(!isRendering);
  };

  return (
    <>
    {/*
      <label>End Video At: </label>
      <button>Last Frame : End of Music</button>
      <br/>
      */}
      {loaded ? (
        <>
          <button onClick={load} disabled={isAnimationPlaying} className="render-button">Render Video</button>
        </> 
        ) : (
          <button onClick={load} disabled={isAnimationPlaying} className="render-button">Render Video</button>
        )}
      <br/>
      <p ref={messageRef} style={{display: "none"}}></p>
      {/*
      <p>{timeValue ? `Time: ${timeValue}` : 'Extracting time...'}</p>
      <p>{totalRenderTime ? `Total Render Time: ${totalRenderTime}` : 'Extracting time...'}</p>
       */}
       {isRendering && <div className="render-progress">
          <p>Rendering Video:</p>    
          <p>{timeValue && totalRenderTime ? `${TimeConverter(timeValue,totalRenderTime)}%` : 'Calculating progress...'}</p> 
          <progress value={convertTimeToSeconds2(timeValue)} max={convertTimeToSeconds2(totalRenderTime)}> {TimeConverter(timeValue,totalRenderTime)}32% </progress>
       </div>}
       {/*
       <p>{isRendering}</p>
       <button onClick={toggleRendering}>Toggle Rendering</button>
        */}
      <video ref={videoRef} controls style={{display: 'none'}}></video><br/>
      <div className="app-footer">
        <p>v 1.0.0.a</p>
        <Link to="/devblog" className='url-link'>Dev Blog</Link>
      </div>
      <br/>
    </>
  )
}
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { useRef } from 'react';

const ffmpeg = new FFmpeg({ log: true });

export default function FFMPEGPlayground() {
  const fileRef = useRef();

  const convertToMp4 = async () => {
    const file = fileRef.current.files[0];
    const { name } = file;
    await ffmpeg.load();

    // Load file into ffmpeg
    const data = await ffmpeg.fetchFile(file);

    // Run ffmpeg command to convert file to mp4
    await ffmpeg.run('-i', data, 'output.mp4');

    // Read the result
    const result = ffmpeg.FS('readFile', 'output.mp4');

    // Create a URL for the mp4 file
    const url = URL.createObjectURL(new Blob([result.buffer], { type: 'video/mp4' }));
    console.log(url);
  };

  return (
    <div>
      <input type="file" ref={fileRef} />
      <button onClick={convertToMp4}>Convert</button>
    </div>
  );
}